@font-face {
  font-family: "Nineteenth";
  src: url("fonts/Nineteenth.woff2") format("woff2"),
    url("fonts/Nineteenth.woff") format("woff");
}

@font-face {
  font-family: "Outfit";
  src: url("fonts/Outfit.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  width: 100vw;
  margin: 0 auto;
  overflow: hidden;
  font-family: Nineteenth;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.navbar-wrapper {
  position: absolute;
  width: 100%;
  margin: 0;
  height: 100px;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  position: relative;
  padding-left: 0;
}

.logo {
  position: absolute;
  top: 8px;
  left: 15px;
  width: 56px;
  height: 70px;
  opacity: 1;
  z-index: 2;
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.top-stripe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 80px;
  background: #000000;
  z-index: 2;
}

.top-stripe-texture {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 500px;
  height: 100%;
  z-index: 3;
}

.top-stripe-texture img {
  position: absolute;
  top: -10px;
  width: 100%;
  object-fit: scale-down;
  object-position: top;
  height: 150px;
  left: 0;
  scale: 1.2;
  pointer-events: none;
}

.top-long {
  position: absolute;
  left: -40px;
  top: -27px;
  height: 144px;
  opacity: 1;
  z-index: 2;
}

.top-long-unobstructive {
  pointer-events: none;
}

.top-long img {
  height: 100%;
  z-index: 2;
}

.flags-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  min-width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flag-polish,
.flag-english {
  width: 62px;
  height: 34px;
  cursor: pointer;
  position: relative;
  filter: grayscale(100%);
  opacity: 1;
  z-index: 3;
}

.flag-polish {
  margin-right: 25px;
}

.flag-english img,
.flag-polish img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.flag-english:hover,
.flag-polish:hover {
  filter: grayscale(0%);
}

.graphics-container {
  display: flex;
  position: relative;
  z-index: 1;
  height: calc(100vh - 80px);
  margin-top: 80px;
  width: 100%;
}

.graphic-container-item {
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
}

.background {
  height: 100%;
  width: 100%;
  background-size: cover;
  filter: brightness(0.13) grayscale(100%);
  z-index: -1;
  transition: filter 0.666s ease-in-out;
}
.graphic-container-item:hover .background {
  filter: brightness(0.7);
}

.graphic-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  top: -5%;
  height: 100%;
  width: 100%;
  position: absolute;
  text-align: center;
  letter-spacing: -1.23px;
  color: #ffffff;
  opacity: 1;
  font-weight: normal;
  font-size: 50px;
}

.goto-btn {
  position: absolute;
  top: 16%;
  width: 230px;
  height: 70px;
  border: 5px solid #ffffff;
  border-radius: 40px;
  opacity: 1;
  font-size: 50px;
  color: #ffffff;
  letter-spacing: -1.25px;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.6s ease-in-out, border-color 0.3s ease-in-out,
    transform 0.2s ease-in-out;
  line-height: 70px;
  -webkit-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    transform 0.2s ease-in-out;
  -moz-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    transform 0.2s ease-in-out;
  -o-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    transform 0.2s ease-in-out;
  margin-bottom: 10px;
}

.goto-btn:hover {
  transform: scale(1.1);
  border: #ffffff solid 7px;
  font-weight: bold;
}

.graphic-text {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  top: calc(23% + 70px + 10px);
}

.reservation-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 85%;
  width: 261px;
  height: 80px;
  background: linear-gradient(#936933, #b3803e, #936933) 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 28px 62px #a39d8985;
  border-radius: 40px;
  opacity: 1;
  font-size: 33px;
  text-align: center;
  color: #ffffff;
  letter-spacing: -0.75px;
  cursor: pointer;
  border: none;
  -webkit-transition: background-color 0.3s ease-in-out,
    transform 0.2s ease-in-out;
  -moz-transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out;
  -o-transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out;
  transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out;
}

.reservation-btn:hover {
  background-color: #a39d89;
  transform: scale(1.1);
}

.footer-wrapper {
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  height: 100px;
  z-index: 5;
  display: none;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: black;
  z-index: 5;
}

.footer-bottom-right {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 5;
  width: 100px;
  height: 100px;
}

.footer-bottom-right-img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.slogan-footer {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 30px;
  left: 110px;
  z-index: 6;
}

.slogan-footer-txt-mid {
  z-index: 6;
  color: #ffffff;
  font-size: 8px;
  margin-top: -2px;
}

.slogan-footer-txt-mid b {
  font-size: 8px;
  font-weight: bold;
  font-family: Outfit-Regular, sans-serif;
}

.slogan-footer-txt-down {
  font-size: 8px;
  color: #ffffff;
  margin-top: -2px;
}

.slogan-footer-txt-up {
  font-size: 18px;
  letter-spacing: 0px;
  opacity: 1;
  margin-bottom: 5px;
  color: #b4813e;
}

.graphic-overlay {
  color: #ffffff;
  display: none;
}

.footer-social-block {
  bottom: -10px;
  left: 12px;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  z-index: 7;
}

.footer-background {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 125px;
  width: 100%;
  z-index: 4;
}

.footer-background-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 3;
}

.regulamin {
  position: absolute;
  z-index: 999999;
  right: 30px;
  top: 55px;
}
.regulamin span {
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}
@media screen and (max-width: 1000px) {
  .regulamin {
    position: absolute;
    z-index: 999999;
    right: 27px;
    top: 35px;
  }
  .regulamin span {
    color: #ffffff;
    font-size: 10px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
  }
}
@media screen and (max-width: 1000px) {
  .graphic-disabled .graphic-content {
    opacity: 0;
    transition: opacity 0.666s ease-in-out;
  }
  .graphic-disabled .graphic-overlay {
    opacity: 1;
  }
  .graphic-enabled .graphic-overlay {
    opacity: 0;
  }
  .graphic-enabled .graphic-content {
    opacity: 1;
  }
  .graphic-enabled .graphic-container-item:hover .background {
    filter: brightness(0.5) grayscale(0%);
  }
  .graphic-enabled .background {
    filter: brightness(0.5) grayscale(0%);
    transition: filter 0.666s ease-in-out;
  }
  .footer-wrapper {
    display: flex;
  }
  .flag-polish,
  .flag-english {
    width: 24px;
    height: 13px;
  }
  .graphic-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    z-index: 3;
    color: #ffffff;
    opacity: 1;
    transition: opacity 0.666s ease-in-out;
  }
  .overlay-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #ffffff;
  }
  .go-to-overlay {
    font-size: 15px;
  }
  .graphic-overlay .overlay-content h2.extra-margin span {
    margin-right: 30px;
  }
  .graphics-container {
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 160px);
    margin-top: 60px;
    margin-bottom: 100px;
  }
  .graphic-container-item {
    height: calc(100% / 3);
  }
  .background {
    background-position: top center;
  }
  .graphic-container-item.graphic-krk .background {
    background-position: center 20%;
  }
  .graphic-container-item.graphic-primo .background {
    background-position: center 69%;
  }
  .graphic-container-item.graphic-zako .background {
  }
  .graphic-content {
    opacity: 0;
    transition: opacity 0.666s ease-in-out;
  }
  .graphic-text {
    font-size: 20px;
    top: calc(30% + 25px);
  }
  .goto-btn {
    width: 60px;
    height: 25px;
    font-size: 15px;
    line-height: 24px;
    border: 3px solid;
  }
  .goto-btn:hover {
    transform: scale(1.1);
    border: 3px solid #ffffff;
  }
  .reservation-btn {
    width: 80px;
    height: 30px;
    font-size: 15px;
    color: black;
    line-height: 20px;
    top: 80%;
  }
  .top-stripe {
    height: 60px;
  }
  .top-stripe-texture {
    height: 60px;
    width: 480px;
    scale: 1;
    top: -10px;
    z-index: 0;
  }
  .top-long {
    position: absolute;
    left: -40px;
    top: -40px;
  }

  .logo {
    position: absolute;
    width: 42px;
    height: 55px;
    left: 10px;
  }
}

@media screen and (max-width: 374px) {
  .slogan-footer-txt-up {
    font-size: 14px;
  }
  .slogan-footer-txt-mid,
  .slogan-footer-txt-down {
    font-size: 6px;
  }
  .goto-btn {
    top: 20%;
    width: 50px;
    height: 15px;
    line-height: 12px;
    border: 2px solid;
  }
  .reservation-btn {
    top: 70%;
    font-size: 12px;
    width: 60px;
    height: 22px;
  }
}
@media (min-width: 700px) and (max-width: 1000px) {
  .slogan-footer {
    top: 10px;
    left: 250px;
  }
  .slogan-footer-txt-mid {
    font-size: 16px;
  }

  .slogan-footer-txt-mid b {
    font-size: 16px;
  }

  .slogan-footer-txt-down {
    font-size: 16px;
  }

  .slogan-footer-txt-up {
    font-size: 28px;
  }
}
